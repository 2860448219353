import React, { useState } from 'react';

const PrivacyPolicy = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Privacy Policy</h1>
      <p><strong>Last updated:</strong> October 31, 2024</p>

      <p>
        This Privacy Notice for CraZed Entertainment, LLC (doing business as Akture) ("we," "us," or "our"),
        describes how and why we might access, collect, store, use, and/or share ("process") your personal
        information when you use our services ("Services"), including when you:
      </p>
      <ul>
        <li>Visit our website at <a href="http://Akture.video">http://Akture.video</a>, or any website of ours that links to this Privacy Notice</li>
        <li>Engage with us in other related ways, including any sales, marketing, or events</li>
      </ul>

      <p>
        Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and choices.
        If you still have any questions or concerns, please contact us at <a href="mailto:Contact@Akture.video">Contact@Akture.video</a>.
      </p>

      <h2>Summary of Key Points</h2>
      <ul>
        <li>What personal information do we process? <button onClick={() => toggleSection('info')}>Learn more</button></li>
        {expandedSection === 'info' && (
          <p>When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us...</p>
        )}
        <li>Do we process any sensitive personal information? No, we do not process sensitive personal information.</li>
        <li>Do we collect any information from third parties? No, we do not collect information from third parties.</li>
        <li>How do we process your information? <button onClick={() => toggleSection('process')}>Learn more</button></li>
        {expandedSection === 'process' && (
          <p>We process your information to provide, improve, and administer our Services...</p>
        )}
      </ul>

      <h2>Table of Contents</h2>
      <ol>
        <li><button onClick={() => toggleSection('section1')}>What Information Do We Collect?</button></li>
        {expandedSection === 'section1' && (
          <div>
            <h3>Personal Information You Disclose to Us</h3>
            <p>We collect personal information that you voluntarily provide to us when you register on the Services...</p>

            <h3>Information Automatically Collected</h3>
            <p>
              Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is
              collected automatically when you visit our Services.
            </p>
          </div>
        )}
        <li>How Do We Process Your Information?</li>
        <li>When and With Whom Do We Share Your Personal Information?</li>
        <li>Do We Use Cookies and Other Tracking Technologies?</li>
        <li>How Long Do We Keep Your Information?</li>
        <li>How Do We Keep Your Information Safe?</li>
        <li>Do We Collect Information From Minors?</li>
        <li>What Are Your Privacy Rights?</li>
        <li>Controls for Do-Not-Track Features</li>
        <li>Do United States Residents Have Specific Privacy Rights?</li>
        <li>Do We Make Updates to This Notice?</li>
        <li>How Can You Contact Us About This Notice?</li>
        <li>How Can You Review, Update, or Delete the Data We Collect From You?</li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
