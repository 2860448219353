import React, { useState, useEffect } from "react";
import api from "../../utils/api";
import CourtInfo from "./CourtInfo";
import styled from "styled-components";

const Courts = () => {
  const [courts, setCourts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCourts = async () => {
    try {
      const response = await api.get("/users/courts");
      console.log(response.data)
      setCourts(response.data);
    } catch (error) {
      console.error("Error fetching courts:", error);
      setError("Failed to load courts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourts();
  }, []);

  return (
    <Container>
      <Header>Courts Information</Header>
      {loading && <p>Loading courts...</p>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!loading && !error && courts.length === 0 && <p>No courts available.</p>}
      {!loading && !error && courts.length > 0 && <CourtInfo courts={courts} />}
    </Container>
  );
};

export default Courts;

/* Styled Components */
const Container = styled.div`
  padding: 24px;
`;

const Header = styled.h1`
  font-size: 2rem;
  margin-bottom: 16px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin: 16px 0;
`;
