// src/components/Video/useFilteredVideos.js

import { useState, useEffect, useMemo } from 'react';
import { getCourtName } from '../../utils/helper';

// Helper function to parse time strings to seconds since midnight
const parseTimeToSeconds = (timeString) => {
  if (!timeString) return NaN;
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12; // Convert PM hours to 24-hour format
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0; // Convert 12 AM to 00 hours
  }

  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59
  ) {
    console.warn(
      `Invalid time format: ${timeString}. Expected HH:MM AM/PM format.`
    );
    return NaN;
  }

  return hours * 3600 + minutes * 60;
};

const useFilteredVideos = (videos, selectedDate, startTime, endTime, selectedCourt) => {
  const [filteredVideos, setFilteredVideos] = useState([]);

  const filteredVideosMemoized = useMemo(() => {
    if (!videos || videos.length === 0 || !selectedDate || !startTime || !endTime) {
      return [];
    }

    // Set the base dates to compare against
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset to midnight

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const twoDaysAgo = new Date(today);
    twoDaysAgo.setDate(today.getDate() - 2);

    // Convert start and end times to seconds since midnight
    const startSeconds = parseTimeToSeconds(startTime);
    let endSeconds = parseTimeToSeconds(endTime);

    if (isNaN(startSeconds) || isNaN(endSeconds)) {
      return [];
    }

    // Adjust for time range crossing midnight
    if (startSeconds >= endSeconds) {
      endSeconds += 24 * 3600; // End time next day
    }

    // Calculate timestamps for filtering
    const baseDate =
      selectedDate === 'yesterday'
        ? yesterday
        : selectedDate === 'last2days'
        ? twoDaysAgo
        : today;
    const startTimestamp = baseDate.getTime() + startSeconds * 1000;
    const endTimestamp = baseDate.getTime() + endSeconds * 1000;

    const filtered = videos.filter((video) => {
      // Convert video start and end times to timestamps
      const videoStartTime = new Date(video.startTime).getTime();
      const videoEndTime = new Date(video.endTime).getTime();

      // Normalize video upload date to midnight for date-only comparison
      const videoUploadDateNormalized = new Date(videoStartTime);
      videoUploadDateNormalized.setHours(0, 0, 0, 0);

      // Check if the video was uploaded on the selected date
      const isOnSelectedDate =
        selectedDate === 'today'
          ? videoUploadDateNormalized.getTime() === today.getTime()
          : selectedDate === 'yesterday'
          ? videoUploadDateNormalized.getTime() === yesterday.getTime()
          : selectedDate === 'last2days'
          ? videoUploadDateNormalized.getTime() >= twoDaysAgo.getTime() &&
            videoUploadDateNormalized.getTime() <= today.getTime()
          : false;

      if (!isOnSelectedDate) {
        return false;
      }

      // Check if the video time overlaps with the selected time range
      const overlaps =
        videoStartTime < endTimestamp && videoEndTime > startTimestamp;

      if (!overlaps) {
        return false;
      }

      // **Court Filtering Logic**
// Court Filtering Logic
if (selectedCourt) {
  if (getCourtName(video.title) !== selectedCourt) {
    console.log('Court does not match: selectedCourt: ', video.courtName, selectedCourt);
    return false; // Exclude videos not matching the selected court
  }
}


      return true; // Video passes all filters
    });

    return filtered;
  }, [videos, selectedDate, startTime, endTime, selectedCourt]);

  useEffect(() => {
    setFilteredVideos(filteredVideosMemoized);
  }, [filteredVideosMemoized]);

  return filteredVideos;
};

export default useFilteredVideos;