import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Hls from 'hls.js'; // Import HLS

import api from '../../utils/api';

const CourtInfo = ({ courts }) => {
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [hlsUrl, setHlsUrl] = useState('');

  const videoRef = useRef(null);

  const handleCourtClick = (court) => {
    setSelectedCourt(court);
    setSelectedCamera(null);
    setHlsUrl('');
  };

  const handleCameraChange = (e) => {
    const camera = e.target.value;
    setSelectedCamera(camera);

    const selectedCameraUrl = selectedCourt?.cameraUrls?.[camera] ?? null;
    if (selectedCameraUrl) {
      fetchHlsUrl(selectedCameraUrl);
    } else {
      setHlsUrl('');
    }
  };

  // Call your backend with the RTSP URL to retrieve HLS
  const fetchHlsUrl = async (rtspUrl) => {
    try {
      const response = await api.post('/streams/start', { rtspUrl });
      console.log('Response:', response.data);
      const newHlsUrl = response.data?.hlsUrl || '';
      console.log("HLS URL String: ", newHlsUrl);

      if (newHlsUrl) {
        // Start polling to check if the manifest is available
        const checkManifest = async (url, retries = 10, delay = 1000) => {
          for (let i = 0; i < retries; i++) {
            try {
              const res = await fetch(url, { method: 'HEAD' });
              if (res.ok) {
                console.log('HLS manifest is available.');
                setHlsUrl(url);
                return;
              }
            } catch (error) {
              console.error('Error checking manifest:', error);
            }
            console.log(`Manifest not available yet. Retrying in ${delay}ms... (${i + 1}/${retries})`);
            await new Promise(resolve => setTimeout(resolve, delay));
          }
          console.error('HLS manifest not available after multiple retries.');
          setHlsUrl(''); // Reset hlsUrl or handle as needed
        };

        checkManifest(newHlsUrl);
      }
    } catch (error) {
      console.error('Error fetching HLS URL:', error);
      setHlsUrl('');
    }
  };

  // Whenever hlsUrl changes, (re)initialize hls.js
  useEffect(() => {
    if (!hlsUrl) return;

    console.log('HLS URL changed to:', hlsUrl);

    const video = videoRef.current;

    if (Hls.isSupported()) {
      const hls = new Hls({
        // Optional: Configure Hls.js settings
        maxRetryAttempts: 10,
        retryDelay: 1000,
      });
      console.log('Initializing hls.js');
      hls.loadSource(hlsUrl);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log('Manifest parsed, attempting to play');
        video.play().catch((err) => console.error('Video play error', err));
      });

      hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
        console.log('Level loaded:', data);
      });

      hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
        console.log('Fragment loaded:', data);
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('HLS.js error:', data);
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error('Fatal network error encountered, trying to recover');
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error('Fatal media error encountered, trying to recover');
              hls.recoverMediaError();
              break;
            default:
              console.error('Unrecoverable error encountered, destroying hls.js instance');
              hls.destroy();
              break;
          }
        }
      });

      // Cleanup on unmount or if URL changes
      return () => {
        console.log('Destroying hls.js instance');
        hls.destroy();
      };
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      console.log('Using native HLS support');
      video.src = hlsUrl;
      video.addEventListener('loadedmetadata', () => {
        video.play().catch((err) => console.error('Video play error', err));
      });
    } else {
      console.warn('HLS not supported in this browser');
    }
  }, [hlsUrl]);

  const cameraNames = selectedCourt ? Object.keys(selectedCourt.cameraUrls || {}) : [];
  const selectedCameraUrl = selectedCamera ? selectedCourt.cameraUrls[selectedCamera] : null;

  return (
    <Container>
      <ListContainer>
        <ListHeader>All Courts</ListHeader>
        <CourtList>
          {courts.map((court) => (
            <CourtItem
              key={court.courtId}
              onClick={() => handleCourtClick(court)}
              isSelected={selectedCourt && selectedCourt.courtId === court.courtId}
            >
              <CourtName>{court.courtName}</CourtName>
              <CourtCameraCount>
                {court.cameraUrls ? Object.keys(court.cameraUrls).length : 0} camera(s)
              </CourtCameraCount>
            </CourtItem>
          ))}
        </CourtList>
      </ListContainer>

      {selectedCourt && (
        <DetailsContainer>
          <CourtDetails>
            <h2>{selectedCourt.courtName}</h2>
            {cameraNames.length > 0 ? (
              <>
                <CameraLabel>Select Camera:</CameraLabel>
                <CameraSelect value={selectedCamera || ''} onChange={handleCameraChange}>
                  <option value="" disabled>
                    -- Choose a camera --
                  </option>
                  {cameraNames.map((camera) => (
                    <option key={camera} value={camera}>
                      {camera}
                    </option>
                  ))}
                </CameraSelect>

                {selectedCameraUrl && (
                  <CameraDetails>
                    <p>
                      Camera URL: <CameraUrl>{selectedCameraUrl}</CameraUrl>
                    </p>
                  </CameraDetails>
                )}

                {/* Show the HLS URL and the <video> player if we have a URL */}
                {hlsUrl && (
                  <CameraDetails>
                    <p>
                      HLS URL: <CameraUrl>{hlsUrl}</CameraUrl>
                    </p>

                    {/* The <video> element for playback */}
                    <video
                      ref={videoRef}
                      controls
                      muted
                      style={{ width: '100%', maxWidth: '600px', marginTop: '16px' }}
                    />
                  </CameraDetails>
                )}
              </>
            ) : (
              <p>No cameras available for this court.</p>
            )}
          </CourtDetails>
        </DetailsContainer>
      )}
    </Container>
  );
};

export default CourtInfo;

/* --------------------------------------------
 * STYLED COMPONENTS
 * -------------------------------------------- */

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 16px;
`;

const ListContainer = styled.div`
  flex: 1;
`;

const ListHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 12px;
  color: #333;
`;

const CourtList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CourtItem = styled.div`
  padding: 12px;
  background-color: ${(props) => (props.isSelected ? '#e0f7fa' : '#fff')};
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.isSelected ? '#b2ebf2' : '#f1f1f1')};
  }
`;

const CourtName = styled.h3`
  margin: 0;
  font-size: 1.1rem;
  color: #333;
`;

const CourtCameraCount = styled.p`
  margin: 4px 0 0;
  font-size: 0.9rem;
  color: #666;
`;

const DetailsContainer = styled.div`
  flex: 2;
`;

const CourtDetails = styled.div`
  padding: 16px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #ddd;

  h2 {
    margin: 0 0 12px;
    font-size: 1.5rem;
    color: #333;
  }

  p {
    margin: 8px 0;
    font-size: 1rem;
    color: #666;
  }
`;

const CameraLabel = styled.label`
  display: block;
  margin: 12px 0 4px;
  font-size: 1rem;
  color: #333;
`;

const CameraSelect = styled.select`
  padding: 8px;
  font-size: 1rem;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const CameraDetails = styled.div`
  margin-top: 16px;
`;

const CameraUrl = styled.span`
  font-family: monospace;
  color: #000;
`;
