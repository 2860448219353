import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, requiredRole }) => {
  const user = useSelector((state) => state.auth.user);

  if (!user) {
    // User is not authenticated
    return <Navigate to="/login" />;
  }

  // If a requiredRole is given, check if user.roles includes that role
  if (requiredRole && !user.roles.includes(requiredRole.toUpperCase())) {
    // User does not have the required role
    return <Navigate to="/unauthorized" />;
  }

  // User is authenticated and has the required role (if any)
  return children;
};

export default PrivateRoute;
