import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Form, ProgressBar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { initiateTrim, pollTrimStatus, downloadTrimmedVideo } from '../../store/videoSlice';

const VideoTrimmer = ({ video, onClose }) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);

  // Selectors to access Redux state
  const trimming = useSelector((state) => state.video.trimming);
  const trimmedVideoUrl = useSelector((state) => state.video.trimmedVideos[video.id]);
  const downloadProgress = useSelector((state) => state.video.downloadProgress[video.id] || 0);
  const downloadStatus = useSelector((state) => state.video.downloadStatus[video.id] || 'idle');
  const error = useSelector((state) => state.video.error);

  // Local state for time inputs (minutes and seconds)
  const [startMinutes, setStartMinutes] = useState(0);
  const [startSeconds, setStartSeconds] = useState(0);
  const [endMinutes, setEndMinutes] = useState(0);
  const [endSeconds, setEndSeconds] = useState(0);

  // Handle Trim Action
  const handleTrim = () => {
    const startTotalSeconds = startMinutes * 60 + startSeconds;
    const endTotalSeconds = endMinutes * 60 + endSeconds;

    // Validate times
    if (startTotalSeconds >= endTotalSeconds) {
      toast.error('Start time must be less than end time.');
      return;
    }

    if (videoRef.current 
      && endTotalSeconds 
      > videoRef.current.duration) {
      toast.error('End time exceeds video duration.');
      return;
    }

    // Dispatch initiateTrim action
    dispatch(initiateTrim({ videoId: video.id,
       startSeconds: startTotalSeconds,
       endSeconds: endTotalSeconds }));
  };

  // Polling for trim status using useEffect
  useEffect(() => {
    let intervalId;
    if (trimming.isTrimming && trimming.trackingId) {
      intervalId = setInterval(() => {
        dispatch(pollTrimStatus({ trackingId: trimming.trackingId, videoId: video.id }));
      }, 5000); // Poll every 5 seconds
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [trimming.isTrimming, trimming.trackingId, dispatch, video.id]);

  // Handle Download Action
  const handleDownload = () => {
    if (trimmedVideoUrl) {
      dispatch(downloadTrimmedVideo({ videoId: video.id, trimmedVideoUrl }));
    } else {
      toast.error('Trimmed video URL is not available.');
    }
  };

  return (
    <TrimmerContainer>
      {error && <ErrorMessage role="alert">{error}</ErrorMessage>}
      {!trimmedVideoUrl && (
        <>
          <VideoPlayer
            ref={videoRef}
            src={video.preSignedS3Url}
            controls
            onLoadedMetadata={() => {
              const totalDuration = videoRef.current.duration;
              setEndMinutes(Math.floor(totalDuration / 60));
              setEndSeconds(Math.floor(totalDuration % 60));
            }}
          />
          <Form>
            <TimeInputGroup controlId="startTime">
              <Form.Label>Start Time:</Form.Label>
              <TimeInputRow>
                <TimeInput
                  type="number"
                  value={startMinutes}
                  min="0"
                  onChange={(e) => setStartMinutes(Number(e.target.value))}
                  placeholder="Min"
                />
                <Colon>:</Colon>
                <TimeInput
                  type="number"
                  value={startSeconds}
                  min="0"
                  max="59"
                  onChange={(e) => setStartSeconds(Number(e.target.value))}
                  placeholder="Sec"
                />
              </TimeInputRow>
            </TimeInputGroup>

            <TimeInputGroup controlId="endTime">
              <Form.Label>End Time:</Form.Label>
              <TimeInputRow>
                <TimeInput
                  type="number"
                  value={endMinutes}
                  min="0"
                  onChange={(e) => setEndMinutes(Number(e.target.value))}
                  placeholder="Min"
                />
                <Colon>:</Colon>
                <TimeInput
                  type="number"
                  value={endSeconds}
                  min="0"
                  max="59"
                  onChange={(e) => setEndSeconds(Number(e.target.value))}
                  placeholder="Sec"
                />
              </TimeInputRow>
            </TimeInputGroup>

            <TrimButton onClick={handleTrim} disabled={trimming.isTrimming}>
              {trimming.isTrimming ? 'Trimming...' : 'Trim Video'}
            </TrimButton>
          </Form>
          {trimming.isTrimming && (
            <ProgressBarContainer>
              <ProgressBar now={trimming.progress} label={`${trimming.progress}%`} />
            </ProgressBarContainer>
          )}
        </>
      )}
      {trimmedVideoUrl && (
        <div>
          <p>Video trimming is complete.</p>
          <VideoPlayer src={trimmedVideoUrl} controls />
          <ButtonGroup>
            {downloadStatus === 'loading' && (
              <ProgressBarContainer>
                <ProgressBar now={downloadProgress} label={`${downloadProgress}%`} />
              </ProgressBarContainer>
            )}

            <StyledButton onClick={handleDownload} disabled={downloadStatus === 'loading'}>
              {downloadStatus === 'loading' ? 'Downloading...' : 'Download'}
            </StyledButton>
            <StyledCloseButton variant="secondary" onClick={onClose}>
              Close
            </StyledCloseButton>
          </ButtonGroup>
        </div>
      )}
    </TrimmerContainer>
  );
};

VideoTrimmer.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    preSignedS3Url: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VideoTrimmer;

// Styled Components with Progress Bar Integration
const ProgressBarContainer = styled.div`
  margin-top: 1rem;
`;

const TrimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.spacing.sm};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;

const VideoPlayer = styled.video`
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
`;

const TimeInputGroup = styled(Form.Group)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const TimeInputRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const Colon = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin: 0 ${({ theme }) => theme.spacing.xs};
`;

const TrimButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    cursor: not-allowed;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xs};
  }
`;

const StyledButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.secondary};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accents[0]};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    cursor: not-allowed;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

const StyledCloseButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.accents[2]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBackground};
  }
`;

const ShareContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const SocialButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xs};
  }
`;

const SocialButton = styled.a`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: ${({ theme }) => theme.spacing.xs};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  text-align: center;
`;


const TimeInput = styled(Form.Control)`
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.spacing.xs};
  border: 1px solid ${({ theme }) => theme.colors.border};

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
    box-shadow: none;
  }
`;


