import { teal } from "@mui/material/colors";

export const theme = {
  colors: {
    // Existing colors...
    primaryBackground: '#f9f9f9', // Light grey
    secondaryBackground: '#ffffff', // White
    primary: '#010101', // Black
    secondary: '#BD2026', // Red
    accents: ['#00a693', '#ff6f3c', '#5a7684'], // Teal, orange, and grey
    teal: '#00a693', // Teal
    orange: '#ff6f3c', // Orange
    grey: '#5a7684', // Grey
    textPrimary: '#333333', // Dark text used for headers
    textSecondary: '#666666', // Medium text used for subheaders
    border: '#010101', // Border color matching primary
    inputBackground: '#f9f9f9', // Input background
    inputFocusBackground: '#fdf2f4', // Input focus background
    error: '#e63946', // Error color
    success: '#28a745', // Success color
    disabledBackground: '#e0e0e0', // Disabled input/button background
    disabledBorder: '#d3d3d3', // Disabled border color
    darkBackground: '#111', // Dark background
    lightBackground: '#f1f1f1', // Light background
    white: '#ffffff', // White color
    tooltipBackground: '#555555', // Tooltip background
    title: '#333333', // Title color
  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1200px',
  },
  fontSizes: {
    xs: '0.75rem',   // 12px
    sm: '0.875rem',  // 14px
    md: '1rem',      // 16px
    lg: '1.25rem',   // 20px
    '2xl': '1.5rem', // 24px
  },
  sizes: {
    logo: {
      small: '40px',   // For mobile
      medium: '60px',  // For tablet
      large: '80px',   // For desktop and above
    },
  },
  fonts: {
    primary: "'Epilogue', Alumni Sans, sans-serif",
    secondary: "'Alumni Sans', sans-serif",
  },
  spacing: {
    xxs: '0.125rem',  // 2px
    xs: '0.25rem',    // 4px
    sm: '0.5rem',     // 8px
    md: '1rem',       // 16px
    lg: '1.5rem',     // 24px
    xl: '2rem',       // 32px
  },
  shadows: {
    small: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
    medium: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
    large: '0 1rem 2rem rgba(0, 0, 0, 0.15)',
  },
  transitions: {
    link: 'color 0.3s ease',
    button: 'background-color 0.3s ease, color 0.3s ease',
    input: 'border-color 0.3s ease, box-shadow 0.3s ease',
    card: 'transform 0.3s ease, box-shadow 0.3s ease',
    tooltip: 'opacity 0.3s',
  },
  borderRadius: {
    xs: '0.125rem', // 2px
    sm: '0.025rem', // 1px
    md: '0.5rem',  // 2px
    lg: '1rem',    // 16px
  },
};
