// src/components/Video/styles/SharedStyles.js

import styled, { css, keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si'; // Import TikTok Icon

// Keyframes for spinner animation
export const spin = keyframes`
  to { transform: rotate(360deg); }
`;

// Base button styles
export const buttonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: 500;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.button};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.buttonText || '#fff'};

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.focusOutline || theme.colors.primary};
    outline-offset: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  svg {
    margin-right: 5px;
  }
`;

// Generic action button
export const ActionButton = styled.button`
  ${buttonStyles}
  background-color: ${({ bgColor, theme }) => bgColor || theme.colors.primary};

  &:hover {
    background-color: ${({ hoverColor, theme }) => hoverColor || theme.colors.secondary};
  }
`;

// Download button variant
export const DownloadButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.colors.accents[0]};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

// Share button variant
export const ShareButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.colors.accents[1]};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

// Close button variant
export const CloseButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.colors.secondary};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBackground};
  }
`;

// Spinner Icon
export const SpinnerIcon = styled(FaSpinner)`
  animation: ${spin} 1s linear infinite;
  color: ${({ theme }) => theme.colors.spinnerForeground || '#fff'};
`;

// TikTok Share Button Variant (Optional)
export const TikTokShareButton = styled(ShareButton)`
  background-color: #000; /* TikTok's signature color */
  
  &:hover {
    background-color: #333; /* Darker shade on hover */
  }

  svg {
    color: #fff; /* Ensure icon visibility */
  }
`;

// Button Group
export const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};
  justify-content: center;
`;

// Error Text
export const ErrorText = styled.div`
  color: red;
  margin-top: 10px;
`;

// Video Container
export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
`;

// Styled Video
export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  background-color: #000;
  border-radius: 5px;
  outline: none;
  transition: border 0.3s;

  &:focus {
    border: 2px solid #007bff;
  }
`;
