import React from "react";
import styled from "styled-components";

const TermsContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 80vh;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 16px;
  color: #111;
`;

const Subtitle = styled.h2`
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 12px;
  color: #222;
`;

const Paragraph = styled.p`
  margin-bottom: 16px;
`;

const TermsOfService = () => {
  return (
    <TermsContainer>
      <Title>Terms of Service</Title>
      <Paragraph>Last updated September 12, 2024</Paragraph>
      <Subtitle>Agreement to Our Legal Terms</Subtitle>
      <Paragraph>
        We are CraZed Entertainment, LLC, doing business as Akture ("Company," "we," "us," "our").
      </Paragraph>
      <Paragraph>
        We operate the website <a href="http://akture.video">http://akture.video</a> (the "Site"), as well as any other
        related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the
        "Services").
      </Paragraph>
      <Paragraph>
        Akture provides recreational and competitive athletes with instant access to high-definition video of their time
        on the court or field.
      </Paragraph>
      <Paragraph>
        You can contact us by phone at (+1)5124360546, email at contact@akture.video, or by mail to 460 Bastrop Hwy SB,
        Suite 1217, Austin, TX 78741, United States.
      </Paragraph>
      <Paragraph>
        These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an
        entity ("you"), and CraZed Entertainment, LLC, concerning your access to and use of the Services. You agree
        that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms.
        IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND
        YOU MUST DISCONTINUE USE IMMEDIATELY.
      </Paragraph>
      <Subtitle>Table of Contents</Subtitle>
      <ul>
        <li>1. OUR SERVICES</li>
        <li>2. INTELLECTUAL PROPERTY RIGHTS</li>
        <li>3. USER REPRESENTATIONS</li>
        <li>4. USER REGISTRATION</li>
        <li>5. PRODUCTS</li>
        <li>6. PURCHASES AND PAYMENT</li>
        <li>7. REFUNDS POLICY</li>
        <li>8. PROHIBITED ACTIVITIES</li>
        <li>9. USER GENERATED CONTRIBUTIONS</li>
        <li>10. CONTRIBUTION LICENSE</li>
        <li>11. SERVICES MANAGEMENT</li>
        <li>12. PRIVACY POLICY</li>
        <li>13. TERM AND TERMINATION</li>
        <li>14. MODIFICATIONS AND INTERRUPTIONS</li>
        <li>15. GOVERNING LAW</li>
        <li>16. DISPUTE RESOLUTION</li>
        <li>17. CORRECTIONS</li>
        <li>18. DISCLAIMER</li>
        <li>19. LIMITATIONS OF LIABILITY</li>
        <li>20. INDEMNIFICATION</li>
        <li>21. USER DATA</li>
        <li>22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
        <li>23. CALIFORNIA USERS AND RESIDENTS</li>
        <li>24. MISCELLANEOUS</li>
        <li>25. CONTACT US</li>
      </ul>
      <Subtitle>1. Our Services</Subtitle>
      <Paragraph>
        The information provided when using the Services is not intended for distribution to or use by any person or
        entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or
        which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those
        persons who choose to access the Services from other locations do so on their own initiative and are solely
        responsible for compliance with local laws, if and to the extent local laws are applicable.
      </Paragraph>
      <Paragraph>
        The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and
        Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your
        interactions would be subjected to such laws, you may not use the Services. You may not use the Services in a
        way that would violate the Gramm-Leach-Bliley Act (GLBA).
      </Paragraph>
      <Subtitle>2. Intellectual Property Rights</Subtitle>
      <Paragraph>
        We are the owner or the licensee of all intellectual property rights in our Services, including all source
        code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the
        Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein
        (the "Marks").
      </Paragraph>
      <Paragraph>
        Our Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use
        only.
      </Paragraph>
      {/* Add additional sections as needed */}
    </TermsContainer>
  );
};

export default TermsOfService;
