// Register.jsx

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { register } from '../../store/authSlice';
import debounce from 'lodash/debounce';
import Header from './Header'; // Ensure Header is used appropriately if needed

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const registrationState = useSelector((state) => state.auth);
  const [form, setForm] = useState({ username: '', email: '', password: '', dob: '' });
  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(true);

  const from = location.state?.from || '/explore';
  const videoIds = location.state?.videoIds;
  const duration = location.state?.duration;
  const thumbnails = location.state?.thumbnails;
  const processing = location.state?.processing;

  // Redirect on successful registration
  useEffect(() => {
    if (registrationState.user) {
      if (videoIds && duration && thumbnails) {
        navigate('/confirmation', { state: { videoIds, duration, thumbnails, processing } });
      } else {
        navigate(from);
      }
      setForm({ username: '', email: '', password: '', dob: '' }); // Reset form fields after success
    }
  }, [registrationState.user, navigate, from, videoIds, duration, thumbnails, processing]);

  // Debounced form validation
  useEffect(() => {
    const debouncedValidate = debounce(() => {
      const errors = {};

      // Username Validation
      if (!form.username.trim()) errors.username = 'Username is required.';

      // Email Validation
      if (!form.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email))
        errors.email = 'Please enter a valid email address.';

      // Password Validation
      if (!form.password.trim()) errors.password = 'Password is required.';

      // DOB Validation
      if (!form.dob) {
        errors.dob = 'Date of Birth is required.';
      } else {
        const dobDate = new Date(form.dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const m = today.getMonth() - dobDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dobDate.getDate())) {
          age--;
        }
        if (age < 13) {
          errors.dob = 'You must be at least 13 years old to register.';
        }
      }

      setFormErrors(errors);
      setIsFormValid(Object.keys(errors).length === 0);
    }, 300);

    debouncedValidate();

    return () => {
      debouncedValidate.cancel(); // Clean up debounce
    };
  }, [form]);

  const handleChange = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    dispatch(register(form));
  };

  return (
    <Container>
      {/* Optionally include the Header component */}
      {/* <Header {...headerPropObject} /> */}

      <StyledForm onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="username">Username</Label>
          <Input
            type="text"
            name="username"
            id="username"
            value={form.username}
            onChange={handleChange}
            placeholder="Username"
            disabled={registrationState.loading}
          />
          {formErrors.username && <FormError>{formErrors.username}</FormError>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={form.email}
            onChange={handleChange}
            placeholder="Email Address"
            disabled={registrationState.loading}
          />
          {formErrors.email && <FormError>{formErrors.email}</FormError>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">Password</Label>
          <Input
            type="password"
            name="password"
            id="password"
            value={form.password}
            onChange={handleChange}
            placeholder="Password"
            disabled={registrationState.loading}
          />
          {formErrors.password && <FormError>{formErrors.password}</FormError>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="dob">Date of Birth</Label>
          <Input
            type="date"
            name="dob"
            id="dob"
            value={form.dob}
            onChange={handleChange}
            placeholder="Date of Birth"
            disabled={registrationState.loading}
            max={new Date().toISOString().split("T")[0]} // Prevent selecting future dates
          />
          {formErrors.dob && <FormError>{formErrors.dob}</FormError>}
        </FormGroup>
        <ButtonGroup>
          <Button type="submit" disabled={!isFormValid || registrationState.loading}>
            {registrationState.loading ? 'Signing Up...' : 'Sign Up'}
          </Button>
          <SecondaryButton type="button" onClick={() => navigate('/login')}>
            Already have an account? Login
          </SecondaryButton>
        </ButtonGroup>
      </StyledForm>
      {registrationState.error && <Error>{registrationState.error}</Error>}
      {/* Remove Success message as redirect occurs on success */}
      {/* {registrationState.user && <Success>Registration successful!</Success>} */}
    </Container>
  );
};

export default Register;

/* --------------------------------------------
 * STYLED COMPONENTS
 * -------------------------------------------- */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f7f7f7;
  min-height: 100vh;
`;

const StyledForm = styled.form`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  background-color: #f9f9f9;
  transition: border-color 0.3s;

  &:focus {
    border-color: #e63946; /* Brand red color */
    outline: none;
  }
`;

const FormError = styled.div`
  color: #e63946;
  font-size: 0.875rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  background-color: #e63946; /* Brand red color */
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.2s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover:not(:disabled) {
    background-color: #d62839;
    transform: translateY(-2px);
  }
`;

const SecondaryButton = styled.button`
  padding: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  border-radius: 4px;
  transition: color 0.3s, border-color 0.3s, transform 0.2s;

  &:hover {
    color: #e63946;
    border-color: #e63946;
    transform: translateY(-2px);
  }
`;

const Error = styled.div`
  color: #e63946;
  margin-top: 1rem;
  font-size: 1rem;
`;

// Remove Success styled component as it's no longer used
// const Success = styled.div`
//   color: #2a9d8f;
//   margin-top: 1rem;
//   font-size: 1rem;
// `;

// Optionally, you can add loading indicators or other UI enhancements
